var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "stageline-navigation-icon" }, [
    _vm.type === "ny-publication-requirement" &&
    _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            attrs: {
              id: "ny-publication-requirement",
              xmlns: "http://www.w3.org/2000/svg",
              x: "0px",
              y: "0px",
              viewBox: "0 0 100 100",
              "xml:space": "preserve",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", [
              _c(
                "g",
                { attrs: { transform: "matrix(1, 0, 0, 1, 1380, 655)" } },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Ellipse_8-2_00000017483020541356324300000011506055373702365857_",
                        transform: "translate(9 6)",
                      },
                    },
                    [
                      _c("circle", {
                        staticClass: "st0",
                        attrs: { cx: "-1339", cy: "-611", r: "50" },
                      }),
                      _c("circle", {
                        staticClass: "st1",
                        attrs: { cx: "-1339", cy: "-611", r: "47" },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "g",
                {
                  attrs: { id: "Group_91", transform: "translate(-1781 -796)" },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Group_37",
                        transform: "translate(805.782 493.191)",
                      },
                    },
                    [
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          id: "Rectangle_837",
                          d: "M1000.2,327.8h41.5l0,0v50l0,0h-19.7c-12.1,0-21.9-9.8-21.9-21.9c0,0,0,0,0,0V327.8\n              L1000.2,327.8z",
                        },
                      }),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Group_67",
                            transform: "translate(1030.357 348.142)",
                          },
                        },
                        [
                          _c("path", {
                            staticClass: "st3",
                            attrs: {
                              id: "Path_26",
                              d: "M-5,1.7l3.1,3.5l4.4-7.1",
                            },
                          }),
                          _c("ellipse", {
                            staticClass: "st3",
                            attrs: {
                              id: "Ellipse_10",
                              cx: "-1.2",
                              cy: "1.7",
                              rx: "7.8",
                              ry: "7.7",
                            },
                          }),
                        ]
                      ),
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          id: "Rectangle_838",
                          d: "M1041.7,337.4h4.6l0,0v35.8c0,2.5-2,4.6-4.6,4.6l0,0l0,0V337.4L1041.7,337.4z",
                        },
                      }),
                      _c("path", {
                        staticClass: "st2",
                        attrs: {
                          id: "Path_28",
                          d: "M1046.3,337.4h3.9v34.9c0,3-2.5,5.5-5.5,5.5c0,0,0,0,0,0h-3",
                        },
                      }),
                      _c("rect", {
                        staticClass: "st2",
                        attrs: {
                          id: "Rectangle_839",
                          x: "1005.1",
                          y: "331.5",
                          width: "31.8",
                          height: "7.5",
                        },
                      }),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Group_68",
                            transform: "translate(1014.078 349.202)",
                          },
                        },
                        [
                          _c("line", {
                            staticClass: "st3",
                            attrs: {
                              id: "Line_9",
                              x1: "-9",
                              y1: "-6",
                              x2: "3",
                              y2: "-6",
                            },
                          }),
                          _c("line", {
                            staticClass: "st3",
                            attrs: {
                              id: "Line_10",
                              x1: "-9",
                              y1: "-2.1",
                              x2: "3",
                              y2: "-2.1",
                            },
                          }),
                          _c("line", {
                            staticClass: "st3",
                            attrs: {
                              id: "Line_11",
                              x1: "-9",
                              y1: "1.9",
                              x2: "3",
                              y2: "1.9",
                            },
                          }),
                          _c("line", {
                            staticClass: "st3",
                            attrs: {
                              id: "Line_12",
                              x1: "-9",
                              y1: "5.8",
                              x2: "3",
                              y2: "5.8",
                            },
                          }),
                          _c("line", {
                            staticClass: "st3",
                            attrs: {
                              id: "Line_13",
                              x1: "-9",
                              y1: "9.8",
                              x2: "7.6",
                              y2: "9.8",
                            },
                          }),
                          _c("line", {
                            staticClass: "st3",
                            attrs: {
                              id: "Line_14",
                              x1: "-9",
                              y1: "17.7",
                              x2: "22.8",
                              y2: "17.7",
                            },
                          }),
                          _c("line", {
                            staticClass: "st3",
                            attrs: {
                              id: "Line_15",
                              x1: "-9",
                              y1: "13.7",
                              x2: "22.8",
                              y2: "13.7",
                            },
                          }),
                          _c("line", {
                            staticClass: "st3",
                            attrs: {
                              id: "Line_16",
                              x1: "-9",
                              y1: "21.6",
                              x2: "22.8",
                              y2: "21.6",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "bank_account" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            attrs: {
              id: "bank_account",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Group_90" } }, [
                _c("g", { attrs: { id: "Ellipse_8-2" } }, [
                  _c("circle", {
                    staticClass: "st0",
                    attrs: { cx: "50", cy: "50", r: "50" },
                  }),
                  _c("circle", {
                    staticClass: "cls-4",
                    attrs: { cx: "50", cy: "50", r: "47" },
                  }),
                ]),
                _c("g", { attrs: { id: "Group_36" } }, [
                  _c("g", [
                    _c("path", {
                      staticClass: "cls-3",
                      attrs: {
                        d: "M70.6,65.2h-15c-1.69,.8-3.57,1.26-5.57,1.26s-3.88-.46-5.57-1.26h-15.07v3.9h41.2v-3.9Z",
                      },
                    }),
                    _c("polygon", {
                      staticClass: "cls-3",
                      attrs: {
                        points:
                          "70.6 69.1 29.4 69.1 27 69.1 27 73 73.1 73 73.1 69.1 70.6 69.1",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-3",
                      attrs: {
                        d: "M60.33,61.4v3.76h5.5v-23.5h-5.5v3.76c1.72,2.21,2.75,4.98,2.75,7.99s-1.03,5.78-2.75,7.99Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-3",
                      attrs: {
                        d: "M39.73,45.42v-3.76h-5.5v23.5h5.5v-3.76c-1.72-2.21-2.75-4.98-2.75-7.99s1.03-5.78,2.75-7.99Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-3",
                      attrs: {
                        d: "M55.56,41.6h15.08v-3.9H29.43v3.9h15.08c1.68-.79,3.55-1.24,5.52-1.24s3.84,.45,5.52,1.24Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-3",
                      attrs: {
                        d: "M74.13,37.7l-24.1-14.7-24.2,14.7h48.3Zm-34.5-3.69l10.37-5.81,10.33,5.81h-20.7Z",
                      },
                    }),
                    _c("polygon", {
                      staticClass: "cls-3",
                      attrs: {
                        points: "39.63 34.01 60.33 34.01 50 28.2 39.63 34.01",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-3",
                      attrs: {
                        d: "M60.33,45.42c-1.26-1.62-2.9-2.94-4.78-3.82-1.68-.79-3.55-1.24-5.52-1.24s-3.84,\n                .45-5.52,1.24c-1.88,.88-3.52,2.19-4.78,3.82-1.72,2.21-2.75,4.98-2.75,7.99s1.03,\n                5.78,2.75,7.99c1.25,1.61,2.87,2.91,4.73,3.8,1.69,.8,3.57,1.26,5.57,1.26s3.88-.46,\n                5.57-1.26c1.86-.88,3.48-2.19,4.73-3.8,1.72-2.21,2.75-4.98,2.75-7.99s-1.03-5.78-2.75-7.99Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M54.6,59.78c-.86,.8-2.11,1.26-3.75,1.39v2.46h-1.39v-2.46c-1.06-.06-2.06-.26-3.01-.6-.95-.34-1.71-.77-2.27-1.29l.8-1.7c.52,\n                .48,1.18,.87,1.98,1.18,.8,.31,1.63,.5,2.5,\n                .56v-5.04c-1.01-.25-1.86-.51-2.53-.8-.67-.29-1.24-.72-1.69-1.28s-.68-1.3-.68-2.22c0-1.14,\n                .42-2.11,1.25-2.91s2.05-1.26,3.66-1.39v-2.48h1.39v2.45c.83,.03,1.63,.16,2.4,\n                .39s1.44,.54,2.01,.93l-.72,1.74c-1.17-.74-2.4-1.14-3.69-1.22v5.06c1.06,.26,1.93,\n                .53,2.61,.81,.68,.28,1.26,.7,1.73,1.26,.47,.56,.71,1.3,.71,2.23,0,1.14-.43,\n                2.11-1.29,2.91Zm-7.2-8.43c.48,.35,1.17,.64,2.06,.87v-4.69c-.93,.12-1.62,.39-2.09,\n                .81s-.69,.94-.69,1.55,.24,1.11,.72,1.46Zm5.64,7.2c.49-.41,.73-.93,\n                .73-1.55s-.25-1.12-.75-1.46-1.22-.64-2.16-.9v4.67c.97-.09,1.7-.34,2.18-.75Z",
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "irs" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            attrs: {
              id: "irs",
              xmlns: "http://www.w3.org/2000/svg",
              x: "0px",
              y: "0px",
              viewBox: "0 0 100 100",
              "xml:space": "preserve",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c(
              "g",
              { attrs: { id: "Group_89", transform: "translate(-979 -515)" } },
              [
                _c(
                  "g",
                  { attrs: { transform: "matrix(1, 0, 0, 1, 979, 515)" } },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Ellipse_7-2",
                          transform: "translate(9 6)",
                        },
                      },
                      [
                        _c("circle", {
                          staticClass: "st0",
                          attrs: { cx: "41", cy: "44", r: "50" },
                        }),
                        _c("circle", {
                          staticClass: "st1",
                          attrs: { cx: "41", cy: "44", r: "47" },
                        }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Group_35",
                      transform: "translate(692.131 209.191)",
                    },
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Group_49",
                          transform: "translate(321.972 340.705)",
                        },
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "Group_48",
                              transform: "translate(2.27)",
                            },
                          },
                          [
                            _c("path", {
                              staticClass: "st3",
                              attrs: {
                                id: "Path_23",
                                d: "M16.3-5.8c-2.4-0.3-4.9-0.3-7.3,0C-0.1-4.7-7.3,0.3-9,6.7h43.3C32.5,0.3,25.3-4.7,16.3-5.8z",
                              },
                            }),
                          ]
                        ),
                        _c("rect", {
                          staticClass: "st3",
                          attrs: {
                            id: "Rectangle_815",
                            x: "-9",
                            y: "6.7",
                            width: "47.8",
                            height: "3.1",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Group_58",
                          transform: "translate(324.502 356.487)",
                        },
                      },
                      [
                        _c("g", { attrs: { id: "Group_51" } }, [
                          _c("g", { attrs: { id: "Group_50" } }, [
                            _c("rect", {
                              staticClass: "st3",
                              attrs: {
                                id: "Rectangle_816",
                                x: "-9",
                                y: "-6",
                                width: "6.2",
                                height: "3.7",
                              },
                            }),
                            _c("rect", {
                              staticClass: "st3",
                              attrs: {
                                id: "Rectangle_817",
                                x: "-9",
                                y: "10.7",
                                width: "6.2",
                                height: "3.7",
                              },
                            }),
                            _c("rect", {
                              staticClass: "st3",
                              attrs: {
                                id: "Rectangle_818",
                                x: "-8.3",
                                y: "-2.3",
                                width: "4.7",
                                height: "13",
                              },
                            }),
                          ]),
                        ]),
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "Group_53",
                              transform: "translate(12.194)",
                            },
                          },
                          [
                            _c("g", { attrs: { id: "Group_52" } }, [
                              _c("rect", {
                                staticClass: "st3",
                                attrs: {
                                  id: "Rectangle_819",
                                  x: "-9",
                                  y: "-6",
                                  width: "6.2",
                                  height: "3.7",
                                },
                              }),
                              _c("rect", {
                                staticClass: "st3",
                                attrs: {
                                  id: "Rectangle_820",
                                  x: "-9",
                                  y: "10.7",
                                  width: "6.2",
                                  height: "3.7",
                                },
                              }),
                              _c("rect", {
                                staticClass: "st3",
                                attrs: {
                                  id: "Rectangle_821",
                                  x: "-8.3",
                                  y: "-2.3",
                                  width: "4.7",
                                  height: "13",
                                },
                              }),
                            ]),
                          ]
                        ),
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "Group_55",
                              transform: "translate(24.388)",
                            },
                          },
                          [
                            _c("g", { attrs: { id: "Group_54" } }, [
                              _c("rect", {
                                staticClass: "st3",
                                attrs: {
                                  id: "Rectangle_822",
                                  x: "-9",
                                  y: "-6",
                                  width: "6.2",
                                  height: "3.7",
                                },
                              }),
                              _c("rect", {
                                staticClass: "st3",
                                attrs: {
                                  id: "Rectangle_823",
                                  x: "-9",
                                  y: "10.7",
                                  width: "6.2",
                                  height: "3.7",
                                },
                              }),
                              _c("rect", {
                                staticClass: "st3",
                                attrs: {
                                  id: "Rectangle_824",
                                  x: "-8.3",
                                  y: "-2.3",
                                  width: "4.7",
                                  height: "13",
                                },
                              }),
                            ]),
                          ]
                        ),
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "Group_57",
                              transform: "translate(36.582)",
                            },
                          },
                          [
                            _c("g", { attrs: { id: "Group_56" } }, [
                              _c("rect", {
                                staticClass: "st3",
                                attrs: {
                                  id: "Rectangle_825",
                                  x: "-9",
                                  y: "-6",
                                  width: "6.2",
                                  height: "3.7",
                                },
                              }),
                              _c("rect", {
                                staticClass: "st3",
                                attrs: {
                                  id: "Rectangle_826",
                                  x: "-9",
                                  y: "10.7",
                                  width: "6.2",
                                  height: "3.7",
                                },
                              }),
                              _c("rect", {
                                staticClass: "st3",
                                attrs: {
                                  id: "Rectangle_827",
                                  x: "-8.3",
                                  y: "-2.3",
                                  width: "4.7",
                                  height: "13",
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Group_59",
                          transform: "translate(320.869 376.88)",
                        },
                      },
                      [
                        _c("rect", {
                          staticClass: "st3",
                          attrs: {
                            id: "Rectangle_828",
                            x: "-6.4",
                            y: "-6",
                            width: "44.7",
                            height: "3.5",
                          },
                        }),
                        _c("rect", {
                          staticClass: "st3",
                          attrs: {
                            id: "Rectangle_829",
                            x: "-9",
                            y: "-2.5",
                            width: "50",
                            height: "3.5",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Group_60",
                          transform: "translate(345.869 333.809)",
                        },
                      },
                      [
                        _c("rect", {
                          staticClass: "st3",
                          attrs: {
                            id: "Rectangle_830",
                            x: "-9",
                            y: "-5.4",
                            width: "6.5",
                            height: "3.8",
                          },
                        }),
                        _c("line", {
                          staticClass: "st3",
                          attrs: {
                            id: "Line_7",
                            x1: "-9",
                            y1: "-6",
                            x2: "-9",
                            y2: "0.9",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "finalize_legal_documents" &&
    _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            attrs: {
              id: "finalize_legal_documents",
              xmlns: "http://www.w3.org/2000/svg",
              x: "0px",
              y: "0px",
              viewBox: "0 0 100 100",
              "xml:space": "preserve",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c(
              "g",
              { attrs: { id: "Group_93", transform: "translate(-578 -375)" } },
              [
                _c(
                  "g",
                  { attrs: { transform: "matrix(1, 0, 0, 1, 578, 375)" } },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Ellipse_6-2",
                          transform: "translate(9 6)",
                        },
                      },
                      [
                        _c("circle", {
                          staticClass: "st0",
                          attrs: { cx: "41", cy: "44", r: "50" },
                        }),
                        _c("circle", {
                          staticClass: "st1",
                          attrs: { cx: "41", cy: "44", r: "47" },
                        }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Group_92",
                      transform: "translate(660 72.191)",
                    },
                  },
                  [
                    _c("path", {
                      staticClass: "st3",
                      attrs: {
                        id: "Path_29",
                        d: "M-55.2,342.6H-59v2.4c-0.1,2.2,1.6,4.1,3.8,4.2l0,0c2.2-0.1,3.9-2,3.8-4.2v-12.6\n            c-0.1-2.4,1.7-4.5,4.2-4.7l0,0c2.4,0.2,4.3,2.2,4.2,4.7v45.3h30v-10.3",
                      },
                    }),
                    _c("path", {
                      staticClass: "st3",
                      attrs: {
                        id: "Path_30",
                        d: "M-47.3,327.8h30.7c2,0.1,3.5,1.9,3.4,3.9v5.8",
                      },
                    }),
                    _c("line", {
                      staticClass: "st3",
                      attrs: {
                        id: "Line_17",
                        x1: "-55.2",
                        y1: "349.3",
                        x2: "-43.1",
                        y2: "349.3",
                      },
                    }),
                    _c("path", {
                      staticClass: "st3",
                      attrs: {
                        id: "Path_31",
                        d: "M-37.9,366.5c0,0,6.2-1.4,6.2-3.8s-5.2-4.4-5.2-4.4l4.5,9.1c0,0,3.3-2.4,5.6-2.4",
                      },
                    }),
                    _c("line", {
                      staticClass: "st3",
                      attrs: {
                        id: "Line_18",
                        x1: "-37.9",
                        y1: "372.3",
                        x2: "-25.4",
                        y2: "372.3",
                      },
                    }),
                    _c("line", {
                      staticClass: "st3",
                      attrs: {
                        id: "Line_19",
                        x1: "-31.8",
                        y1: "333.3",
                        x2: "-23.1",
                        y2: "333.3",
                      },
                    }),
                    _c("line", {
                      staticClass: "st3",
                      attrs: {
                        id: "Line_20",
                        x1: "-37.9",
                        y1: "338.3",
                        x2: "-16.9",
                        y2: "338.3",
                      },
                    }),
                    _c("line", {
                      staticClass: "st3",
                      attrs: {
                        id: "Line_21",
                        x1: "-37.9",
                        y1: "343.2",
                        x2: "-16.9",
                        y2: "343.2",
                      },
                    }),
                    _c("line", {
                      staticClass: "st3",
                      attrs: {
                        id: "Line_22",
                        x1: "-37.9",
                        y1: "348.2",
                        x2: "-18.9",
                        y2: "348.2",
                      },
                    }),
                    _c("line", {
                      staticClass: "st3",
                      attrs: {
                        id: "Line_23",
                        x1: "-37.9",
                        y1: "353.2",
                        x2: "-21.5",
                        y2: "353.2",
                      },
                    }),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Group_70",
                          transform: "translate(-12.456 346.109)",
                        },
                      },
                      [
                        _c("path", {
                          staticClass: "st3",
                          attrs: {
                            id: "Path_32",
                            d: "M-7.8,23L-7.8,23c-1-0.5-1.4-1.7-1-2.8l0.6-1.7l7.9-21.6l3.5,1.6L1.4,3.5l-6.7,18.4\n              c-0.3,1-1.3,1.5-2.3,1.2C-7.7,23.1-7.8,23-7.8,23z",
                          },
                        }),
                        _c("line", {
                          staticClass: "st3",
                          attrs: {
                            id: "Line_24",
                            x1: "-4.7",
                            y1: "20.1",
                            x2: "-8.2",
                            y2: "18.5",
                          },
                        }),
                        _c("line", {
                          staticClass: "st3",
                          attrs: {
                            id: "Line_25",
                            x1: "-7.8",
                            y1: "23",
                            x2: "-8.5",
                            y2: "24.9",
                          },
                        }),
                        _c("path", {
                          staticClass: "st3",
                          attrs: {
                            id: "Path_33",
                            d: "M1.4,3.5L1.4,3.5c1.2,0.6,1.7,2.1,1.2,3.3L0.3,13",
                          },
                        }),
                        _c("path", {
                          staticClass: "st3",
                          attrs: {
                            id: "Path_34",
                            d: "M0.4-2.7l0.9-2.5c0.2-0.6,0.8-0.9,1.4-0.7c0,0,0.1,0,0.1,0l0,0c0.6,0.3,0.9,1,0.6,1.7L2.5-1.8",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "form_the_company" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            attrs: {
              id: "form_the_company",
              xmlns: "http://www.w3.org/2000/svg",
              x: "0px",
              y: "0px",
              viewBox: "0 0 100 100",
              "xml:space": "preserve",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c(
              "g",
              { attrs: { id: "Group_88", transform: "translate(-177 -235)" } },
              [
                _c(
                  "g",
                  { attrs: { transform: "matrix(1, 0, 0, 1, 177, 235)" } },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Ellipse_5-2",
                          transform: "translate(9 6)",
                        },
                      },
                      [
                        _c("circle", {
                          staticClass: "st0",
                          attrs: { cx: "41", cy: "44", r: "50" },
                        }),
                        _c("circle", {
                          staticClass: "st1",
                          attrs: { cx: "41", cy: "44", r: "47" },
                        }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Group_103",
                      transform: "translate(509.31 -107.099)",
                    },
                  },
                  [
                    _c("g", { attrs: { id: "Group_113" } }, [
                      _c("g", { attrs: { id: "Group_103-2" } }, [
                        _c("rect", {
                          staticClass: "st4",
                          attrs: {
                            id: "Rectangle_841",
                            x: "-279.1",
                            y: "379.1",
                            width: "21.8",
                            height: "38",
                          },
                        }),
                      ]),
                      _c("g", { attrs: { id: "Group_112" } }, [
                        _c("g", { attrs: { id: "Group_104" } }, [
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_842",
                              x: "-275.8",
                              y: "382.6",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_843",
                              x: "-271.6",
                              y: "382.6",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_844",
                              x: "-267.4",
                              y: "382.6",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_845",
                              x: "-263.2",
                              y: "382.6",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                        ]),
                        _c("g", { attrs: { id: "Group_105" } }, [
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_846",
                              x: "-275.8",
                              y: "386.7",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_847",
                              x: "-271.6",
                              y: "386.7",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_848",
                              x: "-267.4",
                              y: "386.7",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_849",
                              x: "-263.2",
                              y: "386.7",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                        ]),
                        _c("g", { attrs: { id: "Group_106" } }, [
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_850",
                              x: "-275.8",
                              y: "390.8",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_851",
                              x: "-271.6",
                              y: "390.8",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_852",
                              x: "-267.4",
                              y: "390.8",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_853",
                              x: "-263.2",
                              y: "390.8",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                        ]),
                        _c("g", { attrs: { id: "Group_107" } }, [
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_854",
                              x: "-275.8",
                              y: "395",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_855",
                              x: "-267.4",
                              y: "395",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_856",
                              x: "-263.2",
                              y: "395",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                        ]),
                        _c("g", { attrs: { id: "Group_108" } }, [
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_857",
                              x: "-275.8",
                              y: "399.1",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_858",
                              x: "-271.6",
                              y: "399.1",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_859",
                              x: "-267.4",
                              y: "399.1",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_860",
                              x: "-263.2",
                              y: "399.1",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                        ]),
                        _c("g", { attrs: { id: "Group_109" } }, [
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_861",
                              x: "-275.8",
                              y: "403.2",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_862",
                              x: "-271.6",
                              y: "403.2",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_863",
                              x: "-267.4",
                              y: "403.2",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_864",
                              x: "-263.2",
                              y: "403.2",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                        ]),
                        _c("g", { attrs: { id: "Group_110" } }, [
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_865",
                              x: "-275.8",
                              y: "407.3",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_866",
                              x: "-271.6",
                              y: "407.3",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_867",
                              x: "-267.4",
                              y: "407.3",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_868",
                              x: "-263.2",
                              y: "407.3",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                        ]),
                        _c("g", { attrs: { id: "Group_111" } }, [
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_869",
                              x: "-275.8",
                              y: "411.4",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_870",
                              x: "-271.6",
                              y: "411.4",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_871",
                              x: "-267.4",
                              y: "411.4",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                          _c("rect", {
                            staticClass: "st5",
                            attrs: {
                              id: "Rectangle_872",
                              x: "-263.2",
                              y: "411.4",
                              width: "2.6",
                              height: "2.2",
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _c("g", { attrs: { id: "Group_115" } }, [
                      _c("g", { attrs: { id: "Group_114" } }, [
                        _c("path", {
                          staticClass: "st4",
                          attrs: {
                            id: "Path_62",
                            d: "M-281.6,375.9c0-5.1-4.6-9.1-10.1-8.8c-4.5,0.2-8.3,3.6-8.8,8.1c-0.2,2.1,0.5,4.1,1.7,5.8\n                c1,1.7,1.8,3.5,2.4,5.3h10.5c0.6-1.9,1.4-3.7,2.5-5.4C-282.2,379.5-281.6,377.7-281.6,375.9z",
                          },
                        }),
                        _c("path", {
                          staticClass: "st4",
                          attrs: {
                            id: "Path_63",
                            d: "M-289.2,376.6c0.6-0.5,1-1.2,1-2c-0.1-1.6-1.4-2.8-2.9-2.7c-1.6-0.1-2.9,1.2-2.9,2.7\n                c0,0.9,0.4,1.7,1.2,2.2l-1.2,4.9h6.1L-289.2,376.6z",
                          },
                        }),
                      ]),
                    ]),
                    _c("g", { attrs: { id: "Group_118" } }, [
                      _c("g", { attrs: { id: "Group_117" } }, [
                        _c("g", { attrs: { id: "Group_116" } }, [
                          _c("path", {
                            staticClass: "st4",
                            attrs: {
                              id: "Path_64",
                              d: "M-296.7,389.4v6.2h3.1v-3.1h12.1v3.1h3.1v-6.2L-296.7,389.4z",
                            },
                          }),
                        ]),
                        _c("path", {
                          staticClass: "st4",
                          attrs: {
                            id: "Path_65",
                            d: "M-271.9,395.5h-31.2c-2.3,0-4.2,1.4-4.2,3.1V414c0,1.7,1.9,3.1,4.2,3.1h31.2\n                c2.3,0,4.2-1.4,4.2-3.1v-15.4C-267.8,396.9-269.6,395.5-271.9,395.5z",
                          },
                        }),
                      ]),
                      _c("path", {
                        staticClass: "st4",
                        attrs: {
                          id: "Path_66",
                          d: "M-307.3,401.3l3.5,2.3c1.6,1.1,3.5,1.6,5.4,1.6h21.7c1.9,0,3.8-0.5,5.4-1.6l3.5-2.3",
                        },
                      }),
                      _c("rect", {
                        staticClass: "st4",
                        attrs: {
                          id: "Rectangle_873",
                          x: "-293.9",
                          y: "403.3",
                          width: "12.8",
                          height: "3.9",
                        },
                      }),
                    ]),
                    _c("g", { attrs: { id: "Group_119" } }, [
                      _c("line", {
                        staticClass: "st3",
                        attrs: {
                          id: "Line_39",
                          x1: "-306.3",
                          y1: "387.4",
                          x2: "-301.6",
                          y2: "387.4",
                        },
                      }),
                      _c("line", {
                        staticClass: "st3",
                        attrs: {
                          id: "Line_40",
                          x1: "-304",
                          y1: "385.1",
                          x2: "-304",
                          y2: "389.8",
                        },
                      }),
                    ]),
                    _c("g", { attrs: { id: "Group_120" } }, [
                      _c("line", {
                        staticClass: "st3",
                        attrs: {
                          id: "Line_41",
                          x1: "-279.1",
                          y1: "370.4",
                          x2: "-274.4",
                          y2: "370.4",
                        },
                      }),
                      _c("line", {
                        staticClass: "st3",
                        attrs: {
                          id: "Line_42",
                          x1: "-276.7",
                          y1: "368",
                          x2: "-276.7",
                          y2: "372.7",
                        },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.iconStatus === "icon-completed"
      ? _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c(
              "g",
              {
                attrs: {
                  id: "Group_1725",
                  "data-name": "Group 1725",
                  transform: "translate(-190 -629)",
                },
              },
              [
                _c(
                  "g",
                  {
                    staticClass: "teals",
                    attrs: {
                      id: "Ellipse_5",
                      "data-name": "Ellipse 5",
                      transform: "translate(190 629)",
                      "stroke-width": "2",
                    },
                  },
                  [
                    _c("circle", {
                      attrs: { cx: "50", cy: "50", r: "50", stroke: "none" },
                    }),
                    _c("circle", {
                      attrs: { cx: "50", cy: "50", r: "49", fill: "none" },
                    }),
                  ]
                ),
                _c("path", {
                  attrs: {
                    id: "Path_2",
                    "data-name": "Path 2",
                    d: "M216.461,675.571l16.282,16.336,31.486-30.715",
                    transform: "translate(0.039 2.308)",
                    fill: "none",
                    stroke: "#fff",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "bevel",
                    "stroke-width": "6",
                  },
                }),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "business_idea_and_plan" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v2",
            attrs: {
              id: "business_idea_and_plan",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_8-2" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("g", [
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M60.89,41.52c.98-.88,1.61-2.15,1.61-3.57,0-2.65-2.15-4.79-4.79-4.79s-4.79,\n              2.15-4.79,4.79c0,1.57,.76,2.95,1.92,3.83l-1.92,8.57h9.96l-1.98-8.83Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M57.4,28.06c-7.06,.24-12.81,5.69-13.51,12.62-.05,.47-.07,.95-.07,1.43",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M75,42.11c0-9.85-8.33-17.76-18.33-17.07-8.43,.58-15.26,7.4-15.86,15.83-.29,4.19,\n              .92,8.09,3.16,11.2,2,2.78,4.15,4.41,4.15,9.25l.23,1.13h19.05l.19-.93c0-4.87,\n              2.21-6.66,4.25-9.53s3.15-6.2,3.15-9.89Z",
                    },
                  }),
                  _c("g", [
                    _c("rect", {
                      staticClass: "cls-2",
                      attrs: {
                        x: "50.04",
                        y: "62.46",
                        width: "15.69",
                        height: "3.15",
                        rx: "1.54",
                        ry: "1.54",
                      },
                    }),
                    _c("rect", {
                      staticClass: "cls-2",
                      attrs: {
                        x: "50.04",
                        y: "65.59",
                        width: "15.69",
                        height: "3.15",
                        rx: "1.54",
                        ry: "1.54",
                      },
                    }),
                    _c("rect", {
                      staticClass: "cls-2",
                      attrs: {
                        x: "50.04",
                        y: "68.72",
                        width: "15.69",
                        height: "3.15",
                        rx: "1.54",
                        ry: "1.54",
                      },
                    }),
                  ]),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: { x1: "53.19", y1: "75", x2: "62.59", y2: "75" },
                  }),
                ]),
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "34.38", y1: "25", x2: "34.38", y2: "58.41" },
                }),
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "31.25", y1: "43.42", x2: "31.25", y2: "52.96" },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M43.77,62.47c0-3.02-2.45-5.46-5.46-5.46-1.54,0-2.92,.64-3.92,\n            1.66-.99-1.02-2.38-1.66-3.92-1.66-3.02,0-5.46,2.45-5.46,5.46,0,2.58,1.8,4.74,4.21,\n            5.31-.18,.55-.29,1.13-.29,1.74,0,3.02,2.45,5.46,5.46,5.46s5.46-2.45,\n            5.46-5.46c0-.61-.1-1.19-.29-1.74,2.41-.57,4.21-2.73,4.21-5.31Zm-15.41,0c0-1.16,\n            .94-2.11,2.11-2.11s2.11,.94,2.11,2.11-.94,2.11-2.11,2.11-2.11-.94-2.11-2.11Zm6.03,\n            9.16c-1.16,0-2.11-.94-2.11-2.11s.94-2.11,2.11-2.11,2.11,.94,2.11,2.11-.94,2.11-2.11,\n            2.11Zm3.92-7.05c-1.16,0-2.11-.94-2.11-2.11s.94-2.11,2.11-2.11,2.11,.94,2.11,2.11-.94,\n            2.11-2.11,2.11Z",
                  },
                }),
                _c("polygon", {
                  staticClass: "cls-2",
                  attrs: {
                    points:
                      "25 28.01 25 31.81 30.27 31.81 30.27 35.62 25 35.62 25 39.42 34.38 39.42 34.38\n            35.62 34.38 31.81 34.38 28.01 25 28.01",
                  },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "business_contact_info" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v2",
            attrs: {
              id: "business_contact_info",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_7-2" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("g", [
                  _c("g", [
                    _c("polygon", {
                      staticClass: "cls-2",
                      attrs: {
                        points:
                          "53.03 42.19 53.87 41.53 54.71 40.88 64.85 32.94 75 25 53.21 25 31.41 25 41.4\n                32.94 51.38 40.88 52.2 41.53 53.03 42.19",
                      },
                    }),
                    _c("polygon", {
                      staticClass: "cls-2",
                      attrs: {
                        points:
                          "51.38 40.88 52.2 41.53 53.03 42.19 53.87 41.53 54.71 40.88 53.87 41.53 53.03\n                42.19 52.2 41.53 51.38 40.88",
                      },
                    }),
                    _c("polyline", {
                      staticClass: "cls-2",
                      attrs: {
                        points:
                          "41.4 48.82 51.38 40.88 41.4 32.94 31.41 25 31.41 29.67",
                      },
                    }),
                    _c("polygon", {
                      staticClass: "cls-2",
                      attrs: {
                        points:
                          "54.71 40.88 64.85 48.82 75 56.75 75 40.88 75 25 64.85 32.94 54.71 40.88",
                      },
                    }),
                    _c("polyline", {
                      staticClass: "cls-2",
                      attrs: {
                        points:
                          "39.76 56.75 53.21 56.75 75 56.75 64.85 48.82 54.71 40.88 53.87 41.53 53.03\n                42.19 52.2 41.53 51.38 40.88 38.24 51.33",
                      },
                    }),
                  ]),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M36.92,42.53l-3.5-8.13c-.47-1.08-1.56-1.61-2.44-1.17l-3.68,1.8-.68,.34c-.81,\n              .4-1.35,1.25-1.46,2.29h0c-1.24,14.08,5.04,28.65,15.86,36.81h0c.81,.59,1.77,.7,\n              2.58,.3l.68-.34,3.68-1.8c.88-.43,1.22-1.66,\n              .75-2.74l-3.5-8.13c-.47-1.08-1.56-1.61-2.44-1.17l-2.08,1.02c-.88,\n              .43-1.97-.09-2.44-1.17l-4.91-11.39c-.47-1.08-.13-2.31,.75-2.74l2.08-1.02c.88-.43,\n              1.22-1.66,.75-2.74Z",
                    },
                  }),
                ]),
                _c("path", {
                  staticClass: "cls-4",
                  attrs: {
                    d: "M49.67,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-4",
                  attrs: {
                    d: "M53.89,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-4",
                  attrs: {
                    d: "M58.11,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-4",
                  attrs: {
                    d: "M62.33,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-4",
                  attrs: {
                    d: "M66.56,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-4",
                  attrs: {
                    d: "M70.78,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-4",
                  attrs: {
                    d: "M75,63.6c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z",
                  },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "fund_your_business" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v2",
            attrs: {
              id: "fund_your_business",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_8-2" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("g", [
                  _c("polyline", {
                    staticClass: "cls-2",
                    attrs: {
                      points:
                        "25 48.54 31.07 49.53 37.13 50.52 37.24 62.76 37.35 75 32.19 74.75 27.04 74.51",
                    },
                  }),
                  _c("polyline", {
                    staticClass: "cls-2",
                    attrs: { points: "31.51 61.55 31.67 66.05 31.83 70.54" },
                  }),
                ]),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M37.25,53.18c1.65,0,3.36-2.23,6.41-1.84l3.89,.51,3.89,.51c.69,.09,1.32,.39,\n            1.82,.84s.88,1.05,1.07,1.74h0c.3,1.13,.04,2.26-.58,3.11s-1.6,1.41-2.74,\n            1.41h-3.47s-3.47,0-3.47,0",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M38.05,71.16h6.75c2.93,0,5.82-.44,8.6-1.29s5.44-2.11,7.9-3.75l3.42-2.29,\n            3.42-2.29c1.28-.86,2.45-1.86,3.49-3s1.95-2.4,2.71-3.76h0c.57-1.02,.76-2.18,\n            .6-3.28s-.66-2.15-1.49-2.96h0",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M57.76,58.89c2.26,0,4.42-.47,6.38-1.33s3.73-2.1,5.21-3.63,2.68-3.36,\n            3.51-5.39c.83-2.03,1.29-4.26,\n              1.29-6.6s-.46-4.57-1.29-6.6-2.03-3.85-3.51-5.39-3.25-2.77-5.21-3.63-4.12-1.33-6.38-1.33-4.42,\n              .47-6.38,1.33-3.73,2.1-5.21,3.63-2.68,3.36-3.51,5.39-1.29,4.26-1.29,6.6,.46,4.57,1.29,\n              6.6",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M57.76,54.76c3.42,0,6.52-1.43,8.77-3.75s3.63-5.52,3.63-9.06",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M57.76,29.13c-3.42,0-6.52,1.43-8.77,3.75-2.24,2.32-3.63,5.52-3.63,9.06",
                  },
                }),
                _c("g", [
                  _c("g", [
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M61.66,37.91c0-1.11-.44-2.12-1.14-2.85s-1.68-1.18-2.76-1.18-2.05,.45-2.76,\n                1.18-1.14,1.74-1.14,2.85,.44,2.12,1.14,2.85,1.68,1.18,2.76,1.18",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M53.86,45.98c0,1.11,.44,2.12,1.14,2.85s1.68,1.18,2.76,1.18,2.05-.45,2.76-1.18,\n                1.14-1.74,1.14-2.85-.44-2.12-1.14-2.85-1.68-1.18-2.76-1.18",
                      },
                    }),
                  ]),
                  _c("polyline", {
                    staticClass: "cls-2",
                    attrs: { points: "57.76 32.1 57.76 41.95 57.76 51.79" },
                  }),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "online_presence" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            class: _vm.requiresAction ? "required-action" : "icons-v2",
            attrs: {
              id: "online_presence",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_8-2" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "42.25", y1: "69.35", x2: "57.68", y2: "69.35" },
                }),
                _c("polyline", {
                  staticClass: "cls-2",
                  attrs: { points: "42.09 69.35 38.6 75 61.02 75 57.52 69.35" },
                }),
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "25.1", y1: "62.66", x2: "74.9", y2: "62.66" },
                }),
                _c("g", [
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M72.79,38.7h-6.03v17.35c0,1.05-.84,1.9-1.87,1.9h-29.79c-1.03,\n              0-1.87-.85-1.87-1.9v-17.35h-6.03c-1.22,0-2.21,1-2.21,2.24v26.18c0,1.23,.99,2.24,\n              2.21,2.24h45.59c1.22,0,2.21-1,2.21-2.24v-26.18c0-1.23-.99-2.24-2.21-2.24Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M64.89,25h-29.79c-1.03,0-1.87,.85-1.87,1.9v29.15c0,1.05,.84,1.9,1.87,\n              1.9h29.79c1.03,0,1.87-.85,1.87-1.9V26.9c0-1.05-.84-1.9-1.87-1.9Z",
                    },
                  }),
                  _c("g", [
                    _c("polygon", {
                      staticClass: "cls-2",
                      attrs: {
                        points:
                          "48.7 34.07 36.57 34.07 36.57 39.02 36.57 45.98 48.7 45.98 48.7 39.02 48.7 34.07",
                      },
                    }),
                    _c("polygon", {
                      staticClass: "cls-2",
                      attrs: {
                        points:
                          "63.43 34.07 51.3 34.07 51.3 39.02 51.3 45.98 63.43 45.98 63.43 39.02 63.43 34.07",
                      },
                    }),
                  ]),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "36.57",
                      y1: "34.07",
                      x2: "48.7",
                      y2: "45.98",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "48.7",
                      y1: "34.07",
                      x2: "36.57",
                      y2: "45.98",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "51.3",
                      y1: "34.07",
                      x2: "63.43",
                      y2: "45.98",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "63.43",
                      y1: "34.07",
                      x2: "51.3",
                      y2: "45.98",
                    },
                  }),
                ]),
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "33.23", y1: "31.04", x2: "66.77", y2: "31.04" },
                }),
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "36.57", y1: "49.33", x2: "48.7", y2: "49.33" },
                }),
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "36.57", y1: "52.06", x2: "48.7", y2: "52.06" },
                }),
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "36.57", y1: "54.8", x2: "48.7", y2: "54.8" },
                }),
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "51.3", y1: "49.33", x2: "63.43", y2: "49.33" },
                }),
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "51.3", y1: "52.06", x2: "63.43", y2: "52.06" },
                }),
                _c("line", {
                  staticClass: "cls-2",
                  attrs: { x1: "51.3", y1: "54.8", x2: "63.43", y2: "54.8" },
                }),
                _c("path", {
                  staticClass: "cls-5",
                  attrs: {
                    d: "M36.35,28.99c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-5",
                  attrs: {
                    d: "M38.94,28.99c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-5",
                  attrs: {
                    d: "M41.53,28.99c1.28,0,1.28-1.98,0-1.98s-1.28,1.98,0,1.98h0Z",
                  },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "trademarks" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            attrs: {
              id: "trademarks",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_8-2" } }, [
                _c("circle", {
                  staticClass: "st0",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-4",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("path", {
                  staticClass: "cls-3",
                  attrs: {
                    d: "m75,33.4c-8.54-1.54-16.16-4.44-25-8.35-8.84,3.91-16.46,6.81-25,8.35,1.74,24.76,9.36,\n          37.73,25,41.65,15.64-3.92,23.26-16.89,25-41.65h0Z",
                  },
                }),
                _c("g", [
                  _c("g", [
                    _c("path", {
                      staticClass: "cls-3",
                      attrs: {
                        d: "m49.98,30.02c-3.32,0-6.34,1.29-8.61,3.38-2.53,2.34-4.11,5.69-4.1,9.42,0,\n              4.42,2.26,8.32,5.67,10.6.77.52,1.28,1.42,1.28,2.42v1.63c0,.7.27,1.35.7,1.84.42.48,\n              1.01.82,1.67.92.14.02,6.46.02,6.46.02.02,0,.03,0,.05,0,.82-.02,1.55-.39,\n              2.05-.96.43-.49.69-1.14.69-1.84v-1.63c0-1,.5-1.89,1.27-2.41h0c1.31-.9,2.45-2.02,\n              3.36-3.33,0,0,0,0,0,0,1.44-2.07,2.28-4.59,2.27-7.31-.01-7.05-5.73-12.76-12.76-12.75Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-3",
                      attrs: {
                        d: "m54.16,60.4h0c-.21-.09-.44-.14-.68-.14h-6.88c-.24,\n              0-.47.05-.68.15h0c-.6.27-.98.87-.98,1.53v2.75c0,1.73.97,3.24,\n              2.39,4.02.66.36,1.41.57,2.21.57h1.06c.8,0,1.55-.21,2.21-.58,1.41-.79,2.37-2.3,\n              2.37-4.03v-2.74c0-.66-.39-1.25-.99-1.52Z",
                      },
                    }),
                    _c("polygon", {
                      staticClass: "cls-5",
                      attrs: {
                        points: "43.83 47.4 43.83 47.4 43.83 47.4 43.83 47.4",
                      },
                    }),
                    _c("g", [
                      _c("g", [
                        _c("line", {
                          staticClass: "cls-3",
                          attrs: {
                            x1: "46.73",
                            y1: "46.92",
                            x2: "46.73",
                            y2: "39.06",
                          },
                        }),
                        _c("line", {
                          staticClass: "cls-3",
                          attrs: {
                            x1: "50.48",
                            y1: "44.01",
                            x2: "52.81",
                            y2: "46.92",
                          },
                        }),
                        _c("path", {
                          staticClass: "cls-3",
                          attrs: {
                            d: "m46.73,39.06h4.06c1.37,0,2.48,1.11,2.48,2.48h0c0,1.37-1.11,2.48-2.48,\n                  2.48h-4.06v-4.95h0Z",
                          },
                        }),
                      ]),
                      _c("circle", {
                        staticClass: "cls-3",
                        attrs: { cx: "50", cy: "42.99", r: "8.12" },
                      }),
                    ]),
                  ]),
                  _c("line", {
                    staticClass: "cls-3",
                    attrs: {
                      x1: "44.92",
                      y1: "64.21",
                      x2: "54.97",
                      y2: "62.09",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-3",
                    attrs: {
                      x1: "46.23",
                      y1: "66.9",
                      x2: "54.99",
                      y2: "65.05",
                    },
                  }),
                ]),
                _c("path", {
                  staticClass: "cls-3",
                  attrs: {
                    d: "m28.4,35.9c.16,1.46.33,2.87.54,4.23s.43,2.69.68,3.96.53,2.51.83,3.7.63,2.34.98,\n          3.44.73,2.16,1.13,3.18.83,2,1.29,2.93.94,1.83,1.45,2.68,1.05,1.67,1.61,2.44,1.16,1.51,\n          1.78,2.2,1.27,1.35,1.95,1.96,1.38,1.19,2.12,1.73",
                  },
                }),
                _c("path", {
                  staticClass: "cls-3",
                  attrs: {
                    d: "m71.41,36.09c-.53-.11-1.05-.22-1.57-.34s-1.04-.24-1.56-.37-1.03-.26-1.54-.4-1.03-.28-1.54-.43-1.02-.3-1.53-.46",
                  },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "get_dba" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v2",
            attrs: {
              id: "get_dba",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_8-2" } }, [
                _c("circle", {
                  staticClass: "st0",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("g", [
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M49.85,46.22v-5.4c0-.51-.19-.99-.51-1.39s-.8-.73-1.37-.93l-2.44-.85-2.44-.85-2.83,\n              3.58-2.83,3.58-2.83-3.58-2.83-3.58-2.44,.85-2.44,.85c-.57,.2-1.04,.53-1.37,.93s-.51,\n              .89-.51,1.39v6.89c0,.7,.35,1.33,.91,1.78s1.33,.74,2.19,.74h9.33",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M42.6,30.18c0-1.43-.58-2.72-1.52-3.66s-2.23-1.52-3.66-1.52-2.72,.58-3.66,1.52-1.52,\n            2.23-1.52,3.66,.58,2.72,1.52,3.66,2.23,1.52,3.66,1.52,2.72-.58,3.66-1.52,1.52-2.23,\n            1.52-3.66Z",
                    },
                  }),
                ]),
                _c("g", [
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M68.57,45.23v-11.21c0-3.24-2.64-5.86-5.9-5.86h-11.29s3.18,3.16,3.18,3.16",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: { x1: "54.56", y1: "25", x2: "51.38", y2: "28.16" },
                  }),
                ]),
                _c("g", [
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M25.07,53.06v11.21c0,3.24,2.64,5.86,5.9,5.86h11.29l-3.18-3.16",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "39.08",
                      y1: "73.29",
                      x2: "42.26",
                      y2: "70.13",
                    },
                  }),
                ]),
                _c("g", [
                  _c("g", [
                    _c("path", {
                      attrs: {
                        d: "M61.71,66.18c-.13,0-.26-.05-.36-.15-.2-.2-.2-.52,0-.72l1.44-1.43c.2-.2,.53-.2,\n                .73,0,.2,.2,.2,.52,0,.72l-1.44,1.43c-.1,.1-.23,.15-.36,.15Z",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M66.45,66.18c-.13,0-.26-.05-.36-.15-.2-.2-.2-.52,0-.72l1.44-1.43c.2-.2,.53-.2,\n                .73,0s.2,.52,0,.72l-1.44,1.43c-.1,.1-.23,.15-.36,.15Z",
                      },
                    }),
                  ]),
                  _c("g", [
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M66.63,52.1h6.95v2.65c0,1.91-1.55,3.46-3.46,3.46h-.03c-1.91,\n                0-3.46-1.55-3.46-3.46v-2.65h0Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M59.67,52.1h6.95v2.65c0,1.91-1.55,3.46-3.46,3.46h-.03c-1.91,\n                0-3.46-1.55-3.46-3.46v-2.65h0Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M52.72,52.1h6.95v2.65c0,1.91-1.55,3.46-3.46,3.46h-.03c-1.91,\n                0-3.46-1.55-3.46-3.46v-2.65h0Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M45.76,52.1h6.95v2.65c0,1.91-1.55,3.46-3.46,3.46h-.03c-1.91,\n                0-3.46-1.55-3.46-3.46v-2.65h0Z",
                      },
                    }),
                  ]),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "44.34",
                      y: "49.2",
                      width: "30.66",
                      height: "2.89",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "45.76",
                      y: "52.1",
                      width: "27.83",
                      height: "22.9",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "48.4",
                      y: "61.53",
                      width: "7.49",
                      height: "13.47",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "58.69",
                      y: "61.53",
                      width: "12.03",
                      height: "7.5",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "61.69",
                      y1: "65.71",
                      x2: "63.16",
                      y2: "64.25",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "66.44",
                      y1: "65.71",
                      x2: "67.9",
                      y2: "64.25",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "supplies" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v2",
            attrs: {
              id: "supplies",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_5-2" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("g", [
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "65.08",
                      y: "61.12",
                      width: "7.16",
                      height: "6.36",
                      transform: "translate(137.32 128.6) rotate(-180)",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "62.54",
                      y1: "61.12",
                      x2: "49.44",
                      y2: "61.12",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: { x1: "62.54", y1: "64.3", x2: "49.44", y2: "64.3" },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "62.54",
                      y1: "67.48",
                      x2: "49.44",
                      y2: "67.48",
                    },
                  }),
                ]),
                _c("g", [
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "61.31",
                      y: "41.69",
                      width: "4.54",
                      height: "2.91",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M67.86,31.09c0-2.55-2.43-4.56-5.2-4.01-1.69,.34-3.03,1.7-3.3,3.34-.19,1.15,.12,\n              2.23,.76,3.06,.74,.98,1.19,2.13,1.19,3.34v4.85h4.54v-4.85c0-1.23,.48-2.39,1.22-3.4,\n              .49-.66,.78-1.47,.78-2.35Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M58.54,44.59h10.08c1.33,0,2.41,1.08,2.41,2.41v1.29h-14.9v-1.29c0-1.33,1.08-2.41,\n              2.41-2.41Z",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "53.97",
                      y: "48.28",
                      width: "19.22",
                      height: "3.37",
                    },
                  }),
                ]),
                _c("rect", {
                  staticClass: "cls-2",
                  attrs: {
                    x: "28.4",
                    y: "40.21",
                    width: "19.59",
                    height: "10.92",
                    rx: "1.33",
                    ry: "1.33",
                  },
                }),
                _c("g", [
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M46.68,71.47v-14.35c0-.98,.8-1.78,1.78-1.78h.06v-1.72h.77c1.16,0,2.11-.9,\n              2.11-2.02v-15.34c0-1.12-.94-2.02-2.11-2.02h-1.3v2.71H28.4v-2.71h-1.3c-1.16,0-2.11,\n              .9-2.11,2.02v15.34c0,1.12,.94,2.02,2.11,2.02h.77v19.36c0,1.12,.94,2.02,2.11,\n              2.02h16.44c1.07,0,1.94-.76,2.08-1.75h-.03c-.98,0-1.78-.8-1.78-1.78Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M73.22,55.35h-24.76c-.98,0-1.78,.8-1.78,1.78v14.35c0,.98,.8,1.78,1.78,\n              1.78h24.76c.98,0,1.78-.8,1.78-1.78v-14.35c0-.98-.8-1.78-1.78-1.78Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M47.99,36.96v-11.67l-2.51,2.19-2.59-2.49-2.35,2.25-2.21-2.12-2.39,\n              2.3-2.53-2.43-2.66,2.55-2.35-2.25v11.67h19.59Zm-3.65-6.37h0Zm0,3.34h0Z",
                    },
                  }),
                ]),
                _c("g", [
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856",
                      x: "30.73",
                      y: "55.88",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-2",
                      x: "34.72",
                      y: "55.88",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-3",
                      x: "38.71",
                      y: "55.88",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-4",
                      x: "42.71",
                      y: "55.88",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                ]),
                _c("g", [
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-5",
                      x: "30.73",
                      y: "60.08",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-6",
                      x: "34.72",
                      y: "60.08",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-7",
                      x: "38.71",
                      y: "60.08",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-8",
                      x: "42.71",
                      y: "60.08",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                ]),
                _c("g", [
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-9",
                      x: "30.73",
                      y: "64.28",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-10",
                      x: "34.72",
                      y: "64.28",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-11",
                      x: "38.71",
                      y: "64.28",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-12",
                      x: "42.71",
                      y: "64.28",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                ]),
                _c("g", [
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-13",
                      x: "30.73",
                      y: "68.48",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-14",
                      x: "34.72",
                      y: "68.48",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-15",
                      x: "38.71",
                      y: "68.48",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-4",
                    attrs: {
                      id: "Rectangle_856-16",
                      x: "42.71",
                      y: "68.48",
                      width: "2.6",
                      height: "2.2",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "market_business" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v2",
            attrs: {
              id: "market_business",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_5-2" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("g", [
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M55.34,25H26.87c-1.03,0-1.87,.72-1.87,1.61v14.83c0,.89,.84,1.61,1.87,\n              1.61h8.58l5.65,4.85,5.65-4.85h8.58c1.03,0,1.87-.72,\n              1.87-1.61v-14.83c0-.89-.84-1.61-1.87-1.61Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M46.01,30.23c-.55-.57-1.3-.93-2.14-.93s-1.59,.35-2.14,.93c-.26,.28-.48,.6-.63,\n              .97-.15-.36-.36-.69-.63-.97-.55-.57-1.3-.93-2.14-.93s-1.59,.35-2.14,.93-.89,\n              1.36-.89,2.23,.34,1.66,.89,2.23l4.79,5.01,5.02-5.01c.55-.57,.89-1.36,\n              .89-2.23s-.34-1.66-.89-2.23Z",
                    },
                  }),
                ]),
                _c("g", [
                  _c("polygon", {
                    staticClass: "cls-2",
                    attrs: {
                      points:
                        "45.27 51.36 45.27 57.73 45.27 64.09 68.74 70.45 68.74 57.73 68.74 45 45.27 51.36",
                    },
                  }),
                  _c("polygon", {
                    staticClass: "cls-2",
                    attrs: {
                      points:
                        "68.74 45 68.74 57.73 68.74 70.45 75 74.46 75 57.73 75 41 68.74 45",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M31.68,51.29h13.59v12.88h-13.59c-1.82,0-3.29-1.47-3.29-3.29v-6.3c0-1.82,1.47-3.29,3.29-3.29Z",
                    },
                  }),
                  _c("g", [
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "32.55",
                        y1: "54.66",
                        x2: "32.55",
                        y2: "60.79",
                      },
                    }),
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "36.83",
                        y1: "54.66",
                        x2: "36.83",
                        y2: "60.79",
                      },
                    }),
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "41.11",
                        y1: "54.66",
                        x2: "41.11",
                        y2: "60.79",
                      },
                    }),
                  ]),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M38.83,64.17h6.44v10.83h-2.74c-2.04,0-3.7-1.66-3.7-3.7v-7.13h0Z",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "customer_payments" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v2",
            attrs: {
              id: "customer_payments",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_6-2" } }, [
                _c("circle", {
                  staticClass: "st0",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M40.85,27.98h29.68c1.2,0,2.17,.9,2.17,2v23.73H27.3V29.98c0-1.1,.97-2,2.17-2h3.38",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M55.34,65.07H26.79c-.99,0-1.79-.93-1.79-2.08v-7.19c0-1.15,.8-2.08,\n            1.79-2.08h46.43c.99,0,1.79,.93,1.79,2.08v7.19c0,1.15-.8,2.08-1.79,2.08h-6.79",
                  },
                }),
                _c("g", [
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "53.26",
                      y1: "56.38",
                      x2: "68.49",
                      y2: "56.38",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "55.42",
                      y: "56.38",
                      width: "10.92",
                      height: "18.62",
                    },
                  }),
                ]),
                _c("g", [
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "42.28",
                      y1: "36.36",
                      x2: "31.37",
                      y2: "36.36",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "32.9",
                      y: "25",
                      width: "7.82",
                      height: "11.33",
                      transform: "translate(73.63 61.33) rotate(180)",
                    },
                  }),
                  _c("g", [
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "35.5",
                        y1: "28.09",
                        x2: "38.13",
                        y2: "28.09",
                      },
                    }),
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "35.5",
                        y1: "30.67",
                        x2: "38.13",
                        y2: "30.67",
                      },
                    }),
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "35.5",
                        y1: "33.24",
                        x2: "38.13",
                        y2: "33.24",
                      },
                    }),
                  ]),
                ]),
                _c("rect", {
                  staticClass: "cls-2",
                  attrs: {
                    x: "31.85",
                    y: "39.6",
                    width: "11.74",
                    height: "3.72",
                  },
                }),
                _c("rect", {
                  staticClass: "cls-2",
                  attrs: {
                    x: "31.85",
                    y: "46.61",
                    width: "11.74",
                    height: "3.72",
                  },
                }),
                _c("g", [
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "47.47",
                      y: "39.6",
                      width: "3.98",
                      height: "3.72",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "47.47",
                      y: "46.61",
                      width: "3.98",
                      height: "3.72",
                    },
                  }),
                ]),
                _c("g", [
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "55.99",
                      y: "39.6",
                      width: "3.98",
                      height: "3.72",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "55.99",
                      y: "46.61",
                      width: "3.98",
                      height: "3.72",
                    },
                  }),
                ]),
                _c("g", [
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "64.51",
                      y: "39.6",
                      width: "3.98",
                      height: "3.72",
                    },
                  }),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "64.51",
                      y: "46.61",
                      width: "3.98",
                      height: "3.72",
                    },
                  }),
                ]),
                _c("rect", {
                  staticClass: "cls-2",
                  attrs: {
                    x: "47.47",
                    y: "31.97",
                    width: "21.02",
                    height: "4.4",
                  },
                }),
                _c("g", [
                  _c("g", [
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M63.33,63.3c0-.66-.27-1.26-.72-1.69s-1.06-.7-1.73-.7-1.29,.27-1.73,.7-.72,\n                1.03-.72,1.69,.27,1.26,.72,1.69,1.06,.7,1.73,.7",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M58.42,68.08c0,.66,.27,1.26,.72,1.69s1.06,.7,1.73,.7,1.29-.27,1.73-.7,.72-1.03,\n                .72-1.69-.27-1.26-.72-1.69-1.06-.7-1.73-.7",
                      },
                    }),
                  ]),
                  _c("polyline", {
                    staticClass: "cls-2",
                    attrs: { points: "60.87 59.84 60.87 65.69 60.87 71.54" },
                  }),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "hire_first_employee" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v2",
            attrs: {
              id: "hire_first_employee",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_7-2" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("path", {
                  staticClass: "cls-1",
                  attrs: {
                    d: "M53.84,56.92c.18,.1,.36,.18,.55,.25-.19-.07-.37-.15-.55-.25-.52-.29-.98-.69-1.36-1.17,\n            .38,.49,.84,.89,1.36,1.17Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-1",
                  attrs: {
                    d: "M52.62,35.56c.61,.64,1.15,1.39,1.6,2.21-.45-.82-.98-1.57-1.6-2.21Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-1",
                  attrs: {
                    d: "M50.57,33.96c.74,.42,1.43,.96,2.04,1.6-.61-.64-1.3-1.19-2.04-1.6Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M44.11,38.22c1.39,1.73,3.45,2.84,5.75,2.84,1.89,0,\n            3.62-.75,4.96-1.99-.18-.45-.38-.88-.6-1.29-.45-.82-.98-1.57-1.6-2.21s-1.3-1.19-2.04-1.6c-1.11-.63-2.36-.98-3.67-.98-.32,\n            0-.64,.02-.95,.06-1.29,.17-2.5,.69-3.56,1.46-1.96,1.43-3.42,3.77-3.98,6.54,2.27-.02,\n            4.3-1.11,5.69-2.83Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M60.11,59.24c-1.19-1.13-2.72-1.81-4.38-1.81h-.01c-.47,0-.91-.1-1.33-.26l-2.21,\n            5.73-5.27-2.36-5.27,2.36-2.21-5.73h0c-.42,.16-.86,.26-1.33,.26h-.01c-3.82,0-6.92,\n            3.59-6.92,8.02v2.34c1.35,1.51,4.58,3.89,4.58,3.89,2.46,1.57,5.23,2.64,8.19,3.07,.98,\n            .14,1.96,.24,2.97,.24s2-.1,2.97-.24c2.56-.38,4.96-1.25,\n            7.15-2.49-1.26-1.44-2.04-3.34-2.04-5.44,0-3.49,2.13-6.45,5.11-7.59Zm-24.31,12.44v0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M41.63,62.9l5.27-2.36-7.1-3.18,.17-.44c-.18,.1-.36,.18-.55,.25l2.21,5.73Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M54.01,57.35l-7.1,3.18,5.27,2.36,2.21-5.73c-.19-.07-.37-.15-.55-.25l.17,.44Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M46.91,60.53l7.1-3.18-.17-.44c-.52-.29-.98-.69-1.36-1.17-.64-.81-1.05-1.86-1.13-3.02-1.3,.94-2.82,\n            1.48-4.44,1.48s-3.14-.55-4.44-1.48c-.13,1.86-1.11,3.42-2.49,4.19l-.17,.44,7.1,3.18Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M51.35,52.73c.23-.17,.45-.36,.66-.55,1.13-.99,2.04-2.28,2.68-3.79,.61-1.44,.96-3.06,\n            .96-4.79,0-1.62-.31-3.15-.84-4.53-1.33,1.23-3.06,1.99-4.96,1.99-2.3,\n            0-4.35-1.1-5.75-2.84-1.38,1.72-3.41,2.81-5.69,2.83-.17,.82-.26,1.67-.26,2.54,0,.43,\n            .02,.86,.07,1.28,.13,1.25,.44,2.44,.9,3.52,.32,.76,.71,1.46,1.16,2.09,.45,.64,.96,\n            1.21,1.52,1.7,.22,.19,.43,.38,.66,.55,1.3,.94,2.82,1.48,4.44,1.48s3.14-.55,4.44-1.48Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M60.26,53.44h-3.54l3.54-4.28v-16.42c-3.7-3.07-8.32-4.92-13.36-4.92-12.1,0-21.91,\n            10.56-21.91,23.59,0,6.37,2.35,12.14,6.16,16.38v-2.34c0-4.43,3.1-8.02,\n            6.92-8.02h.01c.47,0,.91-.1,1.33-.26,.19-.07,.37-.15,.55-.25,1.38-.77,\n            2.36-2.33,2.49-4.19-.23-.17-.45-.36-.66-.55-.56-.49-1.07-1.06-1.52-1.7-.45-.64-.84-1.34-1.16-2.09-.46-1.08-.77-2.26-.9-3.52-.04-.42-.07-.84-.07-1.28,0-.88,.1-1.73,\n            .26-2.54,.56-2.77,2.02-5.11,3.98-6.54,1.06-.78,2.26-1.29,3.56-1.46,\n            .31-.04,.63-.06,.95-.06,1.31,0,2.55,.35,3.67,.98,.74,.42,1.43,.96,2.04,1.6s1.15,1.39,\n            1.6,2.21c.22,.41,.42,.84,.6,1.29,.54,1.37,.84,2.91,.84,4.53,0,1.73-.36,3.35-.96,\n            4.79-.64,1.51-1.55,2.81-2.68,3.79-.22,.19-.43,.38-.66,.55,.08,1.16,.49,2.21,1.13,3.02,\n            .38,.49,.84,.89,1.36,1.17,.18,.1,.36,.18,.55,.25,.42,.16,.86,.26,1.33,.26h.01c1.66,0,\n            3.19,.68,4.38,1.81,.83-.32,1.73-.5,2.67-.5,1.65,0,3.17,.53,4.43,1.44,.79-2.12,\n            1.32-4.38,1.51-6.75h-8.46Zm-28.27-4.08v0h0v0h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M39.42,57.17h0c-.42,.16-.86,.26-1.33,.26,.47,0,.91-.1,1.33-.26h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M39.97,56.92c-.18,.1-.36,.18-.55,.25h0c.19-.07,.37-.15,.55-.25Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M38.42,41.05c-.17,.82-.26,1.67-.26,2.54,0,.43,.02,.86,.07,\n            1.28-.04-.42-.07-.84-.07-1.28,0-.88,.1-1.73,.26-2.54Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M40.28,50.48c.45,.64,.96,1.21,1.52,1.7-.56-.49-1.07-1.06-1.52-1.7Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M56.72,53.44h18.28V25h-14.74v24.15l-3.54,4.28Zm12.97-24.35h0Zm1.84,3.12h0Zm0,\n            3.43h0Zm-6.36,0h0Zm6.36,3.43h0Zm0,3.43h0Zm0,3.43h0Zm0,3.43h0Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    d: "M67.21,60.18c-1.26-.91-2.78-1.44-4.43-1.44-.94,0-1.84,.18-2.67,.5-2.98,1.13-5.11,\n            4.1-5.11,7.59,0,2.1,.78,4,2.04,5.44,1.42,1.62,3.47,2.65,5.75,2.65,4.3,0,7.78-3.62,\n            7.78-8.09,0-2.76-1.33-5.19-3.36-6.65Z",
                  },
                }),
                _c("path", {
                  staticClass: "cls-2",
                  attrs: {
                    id: "Path_26",
                    d: "M58.99,66.83l3.09,3.68,4.39-7.46",
                  },
                }),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "set_up_payroll" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v2",
            attrs: {
              id: "set_up_payroll",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_6-2" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("g", [
                  _c("g", [
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M66.96,59.05c0-1.01-.37-1.93-.96-2.6s-1.41-1.07-2.31-1.07-1.72,.41-2.31,1.07-.96,\n                1.58-.96,2.6,.37,1.93,.96,2.6,1.41,1.07,2.31,1.07",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M60.41,66.39c0,1.01,.37,1.93,.96,2.6s1.41,1.07,2.31,1.07,1.72-.41,2.31-1.07,\n                .96-1.58,.96-2.6-.37-1.93-.96-2.6-1.41-1.07-2.31-1.07",
                      },
                    }),
                  ]),
                  _c("polyline", {
                    staticClass: "cls-2",
                    attrs: { points: "63.68 53.76 63.68 62.72 63.68 71.68" },
                  }),
                ]),
                _c("g", [
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M52.37,62.72c0-2.32,.6-4.49,1.64-6.34h-3.51c-.65,0-1.19-.58-1.19-1.29v-3.97c0-.71,\n              .53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,1.19,1.29v3.07c2.06-2.31,4.94-3.76,8.14-3.76,\n              2.44,0,4.7,.85,6.55,2.28v-18.19c0-2.64-1.97-4.79-4.41-4.79h-2.83v2.41c0,1.29-.96,\n              2.34-2.15,2.34s-2.15-1.05-2.15-2.34v-2.41h-4.41v2.41c0,1.29-.96,2.34-2.15,\n              2.34s-2.15-1.05-2.15-2.34v-2.41h-4.41v2.41c0,1.29-.96,2.34-2.15,\n              2.34s-2.15-1.05-2.15-2.34v-2.41h-4.41v2.41c0,1.29-.96,2.34-2.15,\n              2.34s-2.15-1.05-2.15-2.34v-2.41h-3.13c-2.43,0-4.41,2.14-4.41,4.79v32.36c0,2.64,1.97,\n              4.79,4.41,4.79h26.55c-2.21-2.24-3.59-5.42-3.59-8.96Zm6.55-21.61c0-.71,.53-1.29,\n              1.19-1.29h3.87c.65,0,1.19,.58,1.19,1.29v3.97c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,\n              0-1.19-.58-1.19-1.29v-3.97Zm-9.62,0c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,\n              1.19,1.29v3.97c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,\n              0-1.19-.58-1.19-1.29v-3.97Zm-12.99,24c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,\n              0-1.19-.58-1.19-1.29v-3.97c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,1.19,\n              1.29v3.97Zm0-10.02c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,\n              0-1.19-.58-1.19-1.29v-3.97c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,1.19,\n              1.29v3.97Zm9.62,10.02c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,\n              0-1.19-.58-1.19-1.29v-3.97c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,\n              1.19,1.29v3.97Zm0-10.02c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,\n              0-1.19-.58-1.19-1.29v-3.97c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,1.19,\n              1.29v3.97Zm0-10.02c0,.71-.53,1.29-1.19,1.29h-3.87c-.65,\n              0-1.19-.58-1.19-1.29v-3.97c0-.71,.53-1.29,1.19-1.29h3.87c.65,0,1.19,.58,1.19,\n              1.29v3.97Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M44.75,39.82h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,1.29h3.87c.65,\n              0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M50.49,46.36h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29h-3.87c-.65,\n              0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,1.29Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M60.11,46.36h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29h-3.87c-.65,\n              0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,1.29Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M35.13,59.85h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,\n              1.29h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M44.75,59.85h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,\n              1.29h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M35.13,49.83h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,\n              1.29h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M44.75,49.83h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,\n              1.29h3.87c.65,0,1.19-.58,1.19-1.29v-3.97c0-.71-.53-1.29-1.19-1.29Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M54.36,49.83h-3.87c-.65,0-1.19,.58-1.19,1.29v3.97c0,.71,.53,1.29,1.19,\n              1.29h3.51c.44-.79,.96-1.53,1.54-2.19v-3.07c0-.71-.53-1.29-1.19-1.29Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M70.24,52.72c-1.85-1.43-4.11-2.28-6.55-2.28-3.2,0-6.08,1.44-8.14,3.76-.59,.66-1.1,\n              1.4-1.54,2.19-1.03,1.85-1.64,4.02-1.64,6.34,0,3.54,1.39,6.72,3.59,8.96,2.02,2.05,\n              4.73,3.32,7.72,3.32,6.25,0,11.32-5.5,11.32-12.28,0-4.13-1.88-7.78-4.76-10Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M34.69,25c-1.19,0-2.15,1.05-2.15,2.34v4.81c0,1.29,.96,2.34,2.15,2.34s2.15-1.05,\n              2.15-2.34v-4.81c0-1.29-.96-2.34-2.15-2.34Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M43.41,25c-1.19,0-2.15,1.05-2.15,2.34v4.81c0,1.29,.96,2.34,2.15,2.34s2.15-1.05,\n              2.15-2.34v-4.81c0-1.29-.96-2.34-2.15-2.34Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M52.13,25c-1.19,0-2.15,1.05-2.15,2.34v4.81c0,1.29,.96,2.34,2.15,2.34s2.15-1.05,\n              2.15-2.34v-4.81c0-1.29-.96-2.34-2.15-2.34Z",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M60.85,25c-1.19,0-2.15,1.05-2.15,2.34v4.81c0,1.29,.96,2.34,2.15,2.34s2.15-1.05,\n              2.15-2.34v-4.81c0-1.29-.96-2.34-2.15-2.34Z",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "find_tax_advisor" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v3",
            attrs: {
              id: "find_tax_advisor",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_8-2" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-4",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("g", [
                  _c("g", [
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M50.28,37.16c-.31-.53-.68-1-1.1-1.41s-.9-.76-1.41-1.02c-.77-.4-1.63-.62-2.53-.62-.22,\n                0-.44,.02-.66,.04-.89,.11-1.72,.44-2.46,.93-1.36,.91-2.36,2.41-2.75,4.17,1.57-.01,\n                2.97-.71,3.93-1.81,.52,.6,1.19,1.08,1.93,1.39,.63,.26,1.31,.42,2.04,.42,1.31,0,\n                2.5-.48,3.42-1.27-.12-.29-.26-.56-.41-.83Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M41.59,55.18l3.64-3.51-4.91-2.03,.12-.28c-.12,.06-.25,.12-.38,.16l1.53,5.65Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M50.7,37.99c-.92,.79-2.11,1.27-3.42,1.27-.72,\n                0-1.41-.15-2.04-.42-.75-.31-1.41-.79-1.93-1.39-.96,1.1-2.36,1.79-3.93,1.81-.11,\n                .52-.18,1.06-.18,1.62,0,.28,.02,.55,.05,.81,.09,.8,.31,1.55,.62,2.24,.22,.48,.49,\n                .93,.8,1.33,.31,.41,.66,.77,1.05,1.08,.15,.12,.3,.24,.46,.35,.9,.6,1.95,.94,3.07,\n                .94s2.17-.35,3.07-.94c.16-.11,.31-.23,.46-.35,.78-.63,1.41-1.46,1.85-2.42,.42-.92,\n                .67-1.95,.67-3.06,0-1.03-.21-2.01-.58-2.89Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M40.06,49.53c-.15,.05-.29,.09-.45,.12,.15-.03,.3-.07,.45-.12h0Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M40.44,49.37c-.12,.06-.25,.12-.38,.16h0c.13-.05,.26-.1,.38-.16Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M39.37,39.25c-.11,.52-.18,1.06-.18,1.62,0,.28,.02,.55,\n                .05,.81-.03-.27-.05-.54-.05-.81,0-.56,.07-1.1,.18-1.62Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M50.02,49.37c-.95-.49-1.63-1.49-1.72-2.67-.9,.6-1.95,.94-3.07,\n                .94s-2.17-.35-3.07-.94c-.09,1.18-.77,2.18-1.72,2.67l-.38,.16,5.17,2.14,\n                5.17-2.14-.38-.16Z",
                      },
                    }),
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "37.52",
                        y1: "58.78",
                        x2: "37.52",
                        y2: "54.62",
                      },
                    }),
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "52.94",
                        y1: "58.87",
                        x2: "52.94",
                        y2: "54.62",
                      },
                    }),
                    _c("polygon", {
                      staticClass: "cls-2",
                      attrs: {
                        points:
                          "45.23 51.67 43.02 53.8 45.23 56.81 47.44 53.8 45.23 51.67",
                      },
                    }),
                    _c("g", [
                      _c("path", {
                        staticClass: "cls-2",
                        attrs: {
                          d: "M51.33,49.69h0c-.16,0-.32-.02-.47-.05-.15-.03-.3-.07-.45-.12l-1.53,\n                  5.65-1.41-1.36-2.23-2.15-2.23,2.15-1.41,1.36-1.53-5.65c-.15,.05-.29,.09-.45,\n                  .12-.15,.03-.48,.05-.48,.05-2.64,0-4.78,2.29-4.78,5.11l.11,1.78c.94,.96,3.06,\n                  2.28,3.06,2.28,1.7,1,3.61,1.6,5.66,1.88l2.06-3.94,2.06,3.94c2.04-.28,3.96-.96,\n                  5.66-1.96,0,0,2.23-1.34,3.17-2.3v-1.67c0-2.82-2.14-5.11-4.78-5.11Z",
                        },
                      }),
                      _c("path", {
                        staticClass: "cls-2",
                        attrs: {
                          d: "M45.23,56.81l-2.06,3.97c.67,.09,1.36,.12,2.06,.12s1.38-.03,2.06-.12l-2.06-3.97Z",
                        },
                      }),
                    ]),
                  ]),
                  _c("path", {
                    staticClass: "cls-2",
                    attrs: {
                      d: "M45.23,29.64c-8.62,0-15.6,7-15.6,15.63s6.99,15.63,15.6,15.63,15.6-7,15.6-15.63",
                    },
                  }),
                  _c("path", {
                    staticClass: "cls-3",
                    attrs: {
                      d: "M60.84,45.27c0-8.63-6.99-15.63-15.6-15.63s-15.6,7-15.6,15.63c0,4.45,1.86,8.47,4.84,\n              11.31,.92,.88,1.94,1.64,3.06,2.28,1.71,.98,3.62,1.64,5.66,1.91,.67,.09,1.36,.13,\n              2.06,.13s1.38-.05,2.06-.13c2.03-.27,3.95-.93,5.66-1.91,1.16-.66,2.22-1.46,3.17-2.39,\n              2.91-2.84,4.72-6.81,4.72-11.21Z",
                    },
                  }),
                  _c("g", [
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M73.92,68.68l-11.97-11.99c-1.4,2.05-3.17,3.83-5.21,5.25l11.95,11.97c1.44,1.45,\n                3.78,1.45,5.22,0h0c1.44-1.45,1.44-3.79,0-5.24Z",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M39.61,64.75c1.78,.52,3.67,.79,5.62,.79,2.14,0,4.2-.33,6.13-.95s3.74-1.52,\n                5.38-2.65c1.02-.71,1.97-1.51,2.84-2.39s1.66-1.84,2.36-2.86c1.11-1.63,1.99-3.42,\n                2.59-5.34s.93-3.96,.93-6.08c0-5.6-2.26-10.67-5.93-14.34s-8.72-5.94-14.31-5.94-10.65,\n                2.27-14.31,5.94-5.93,8.74-5.93,14.34,2.26,10.67,5.93,14.34c.38,.38,.77,.74,1.18,1.09",
                      },
                    }),
                  ]),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: { x1: "67.21", y1: "67.67", x2: "59.55", y2: "60" },
                  }),
                ]),
                _c("g", [
                  _c("circle", {
                    staticClass: "cls-2",
                    attrs: {
                      cx: "27.54",
                      cy: "64.22",
                      r: "2.54",
                      transform: "translate(-18.95 12.06) rotate(-18.5)",
                    },
                  }),
                  _c("circle", {
                    staticClass: "cls-2",
                    attrs: {
                      cx: "35.32",
                      cy: "72.4",
                      r: "2.54",
                      transform: "translate(-21.15 14.95) rotate(-18.5)",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "36.6",
                      y1: "61.91",
                      x2: "26.05",
                      y2: "74.71",
                    },
                  }),
                ]),
                _c("g", [
                  _c("g", [
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M75,30.59c0-1.07-.42-2.04-1.1-2.74s-1.62-1.13-2.65-1.13-1.97,.43-2.65,1.13-1.1,\n                1.67-1.1,2.74,.42,2.04,1.1,2.74,1.62,1.13,2.65,1.13",
                      },
                    }),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M67.5,38.34c0,1.07,.42,2.04,1.1,2.74s1.62,1.13,2.65,1.13,1.97-.43,2.65-1.13,\n                1.1-1.67,1.1-2.74-.42-2.04-1.1-2.74-1.62-1.13-2.65-1.13",
                      },
                    }),
                  ]),
                  _c("polyline", {
                    staticClass: "cls-2",
                    attrs: { points: "71.25 25 71.25 34.46 71.25 43.92" },
                  }),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm.type === "file_taxes" && _vm.iconStatus !== "icon-completed"
      ? _c(
          "svg",
          {
            staticClass: "icons-v2",
            attrs: {
              id: "file_taxes",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 100 100",
              width: _vm.width,
              height: _vm.height,
            },
          },
          [
            _c("g", { attrs: { id: "Layer_1-2" } }, [
              _c("g", { attrs: { id: "Ellipse_8-2" } }, [
                _c("circle", {
                  staticClass: "cls-1",
                  attrs: { cx: "50", cy: "50", r: "50" },
                }),
                _c("circle", {
                  staticClass: "cls-3",
                  attrs: { cx: "50", cy: "50", r: "47" },
                }),
              ]),
              _c("g", [
                _c("g", [
                  _c("g", [
                    _c("g", [
                      _c("path", {
                        staticClass: "cls-2",
                        attrs: {
                          d: "M50.92,37.04c0-3.01,1.11-5.75,2.93-7.86h-7.66l-.03,.03v10.49c0,1.47-1.19,\n                  2.67-2.67,2.67h-10.49v30.1c0,1.4,1.13,2.53,2.53,2.53h32.39c1.4,0,2.53-1.13,\n                  2.53-2.53v-26.01c-2.06,1.64-4.66,2.62-7.49,2.62-6.65,0-12.04-5.39-12.04-12.04Z",
                        },
                      }),
                      _c("path", {
                        staticClass: "cls-2",
                        attrs: {
                          d: "M62.96,25c-3.64,0-6.9,1.62-9.11,4.18-1.82,2.11-2.93,4.85-2.93,7.86,0,6.65,5.39,\n                  12.04,12.04,12.04,2.84,0,5.44-.99,7.49-2.62,2.77-2.21,4.55-5.6,4.55-9.42,\n                  0-6.65-5.39-12.04-12.04-12.04Z",
                        },
                      }),
                    ]),
                    _c("path", {
                      staticClass: "cls-2",
                      attrs: {
                        d: "M46.16,39.71v-10.49l-13.16,13.16h0s10.49,0,10.49,0c1.47,0,2.67-1.19,2.67-2.67Z",
                      },
                    }),
                  ]),
                  _c("rect", {
                    staticClass: "cls-2",
                    attrs: {
                      x: "37.3",
                      y: "47.28",
                      width: "13.61",
                      height: "4.21",
                    },
                  }),
                  _c("g", [
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "37.17",
                        y1: "56.18",
                        x2: "44.69",
                        y2: "56.18",
                      },
                    }),
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "47.84",
                        y1: "56.18",
                        x2: "55.36",
                        y2: "56.18",
                      },
                    }),
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "58.76",
                        y1: "56.18",
                        x2: "66.28",
                        y2: "56.18",
                      },
                    }),
                  ]),
                  _c("g", [
                    _c("g", [
                      _c("line", {
                        staticClass: "cls-2",
                        attrs: {
                          x1: "38.75",
                          y1: "60.89",
                          x2: "45.28",
                          y2: "60.89",
                        },
                      }),
                      _c("line", {
                        staticClass: "cls-2",
                        attrs: {
                          x1: "42.02",
                          y1: "60.94",
                          x2: "42.02",
                          y2: "70.7",
                        },
                      }),
                    ]),
                    _c("g", [
                      _c("line", {
                        staticClass: "cls-2",
                        attrs: {
                          x1: "47.88",
                          y1: "70.7",
                          x2: "51.98",
                          y2: "60.94",
                        },
                      }),
                      _c("line", {
                        staticClass: "cls-2",
                        attrs: {
                          x1: "56.09",
                          y1: "70.7",
                          x2: "51.98",
                          y2: "60.94",
                        },
                      }),
                      _c("line", {
                        staticClass: "cls-2",
                        attrs: {
                          x1: "49.61",
                          y1: "66.57",
                          x2: "54.35",
                          y2: "66.57",
                        },
                      }),
                    ]),
                    _c("g", [
                      _c("line", {
                        staticClass: "cls-2",
                        attrs: {
                          x1: "58.69",
                          y1: "60.89",
                          x2: "64.7",
                          y2: "70.7",
                        },
                      }),
                      _c("line", {
                        staticClass: "cls-2",
                        attrs: {
                          x1: "64.7",
                          y1: "60.89",
                          x2: "58.69",
                          y2: "70.7",
                        },
                      }),
                    ]),
                  ]),
                  _c("g", [
                    _c("circle", {
                      staticClass: "cls-2",
                      attrs: { cx: "60.87", cy: "32.39", r: "2.31" },
                    }),
                    _c("circle", {
                      staticClass: "cls-2",
                      attrs: { cx: "65.22", cy: "41.69", r: "2.31" },
                    }),
                    _c("line", {
                      staticClass: "cls-2",
                      attrs: {
                        x1: "69.35",
                        y1: "33.01",
                        x2: "56.56",
                        y2: "41",
                      },
                    }),
                  ]),
                ]),
                _c("g", [
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "28.99",
                      y1: "42.37",
                      x2: "28.99",
                      y2: "49.89",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "28.99",
                      y1: "53.04",
                      x2: "28.99",
                      y2: "60.56",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      x1: "28.99",
                      y1: "63.95",
                      x2: "28.99",
                      y2: "71.47",
                    },
                  }),
                ]),
                _c("g", [
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: { x1: "25", y1: "39.98", x2: "25", y2: "47.5" },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: { x1: "25", y1: "50.64", x2: "25", y2: "58.16" },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: { x1: "25", y1: "61.56", x2: "25", y2: "69.08" },
                  }),
                ]),
                _c("g", { attrs: { id: "Group_119" } }, [
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      id: "Line_39",
                      x1: "27.88",
                      y1: "33.29",
                      x2: "36.22",
                      y2: "33.29",
                    },
                  }),
                  _c("line", {
                    staticClass: "cls-2",
                    attrs: {
                      id: "Line_40",
                      x1: "31.96",
                      y1: "29.21",
                      x2: "31.96",
                      y2: "37.56",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }